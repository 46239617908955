<template>
  <v-container class="form-container" fill-height fluid>
    <div class="ant-glass-background login-card">
      <div class="login-header">ANT</div>
      <v-form ref="forgotForm" @submit.prevent="onSubmit">
        <ant-input label="Email">
          <template #input-field>
            <v-text-field
              v-model="email"
              :rules="[rules.required]"
              autofocus
              dense
              filled
              placeholder="Email"
              single-line
              type="email"
            />
          </template>
        </ant-input>

        <div class="d-flex">
          <v-btn
            class="d-block flex-grow-1"
            color="primary"
            elevation="0"
            small
            tile
            type="submit"
          >
            SEND RECOVERY LINK
          </v-btn>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                class="ml-2"
                href="https://docs.antcde.io/"
                icon
                target="_blank"
                v-on="on"
              >
                <v-icon class="ant-icon"> mdi-help-circle-outline</v-icon>
              </v-btn>
            </template>
            <span>ANT Documentation</span>
          </v-tooltip>
        </div>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import AntInput from '@/components/AntInput.vue';

export default {
  components: { AntInput },
  data: () => ({
    email: null,
    rules: {
      required: (value) => !!value || 'Required.',
    },
  }),
  methods: {
    onSubmit() {
      if (this.$refs.forgotForm.validate()) {
        this.$store
          .dispatch('requestPasswordReset', { email: this.email })
          .then(() => {
            this.$store.commit('showNotification', {
              content:
                'We have sent you an email with a link to reset your password',
              color: 'success',
            });
            this.$router.push('/');
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  background-image: linear-gradient(-90deg, #e6e9f0 0%, #eef1f5 100%);
  display: flex;
  justify-content: center;

  .login-card {
    padding: 25px 100px;

    .login-header {
      text-align: center;
      font-size: 70px;
      color: var(--v-primary-base);
    }
  }

  .v-icon.icon-question-mark {
    font-size: 34px;
    color: var(--v-primary-base);
  }
}
</style>
